<template>
  <div class="settings" style=" padding-top: 60px; min-width: 1170px">

    <div class="mainpage-body">
    <h1 class="homeH1">
      Yetenekli {{header}}<br><span>daha çok iş yapın.</span>
    </h1>

    <p class="header-info">
      İşlerini hızlı ve kolay bir şekilde yaptıran onbinlerce işletme arasına katılın!
    </p>


      <div v-if="!user.uuid" style="margin: 0 auto; width: 100%; text-align: center; margin-bottom: 55px; margin-top: 50px;">
        <button class="super-button top-buy-button " @click="openOnboardingModal">
          <span>Hemen Başla</span>
        </button>
      </div>
      <div v-else>
        <div class="hiddendiv"></div>
        <div class="hiddendiv"></div>
      </div>
    </div>


    <div class="explore-marketplace-gig-container" :style="index%2 ===1 ? 'background-color: #ffffff;' : 'background-color: #f4f5f7;'"
         v-for="(gigList,index) in gigLists">
      <h2 style="    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 44px;">{{gigList.title}}</h2>
      <div v-html="gigList.description" class="godesc" style="font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
    line-height: 1.44;
    text-align: center;
    color: #2d3640;
    width: 900px;
     margin-bottom: 40px;
    margin-top: 20px;"></div>
      <div style="display: flex; flex-wrap: wrap; justify-content: center; max-width: 1200px">



          <div v-for="(gig, gindex) in gigList.gigs" :key="gindex" class="marketplace-gigs-body" style="margin-bottom: 70px;">
            <single-gig :impression-u-u-i-d="impressionUUID"  :item="gig"  />
          </div>


      </div>

      <div v-if="!user.uuid" style="margin: 0 auto; width: 100%; text-align: center; margin-top: 24px; margin-bottom: 20px; ">
        <button class="super-button top-buy-button " @click="openOnboardingModal">
          <span>Hemen Başla</span>
        </button>
      </div>

    </div>

    <div class="mainpage-illustration">
      <h1 style="margin-bottom: 0px!important;">Neden <span style="font-weight: 600;">Freelancer?</span></h1>
      <p class="header-info" style="margin-bottom: 30px; margin-top: 0px;">Geleceğin "çalışma" modeliyle bugünden tanışın.</p>
      <div class="illustration-body">
        <div class="illustration-inner" style="margin-right: 41px;">
          <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/search_bi.png"/>
          <p class="illustration-main-paragraph">Sınırsızlık, Özgürleştirir
          </p>
          <p class="illustration-paragraph">
            Sınırsız düşünebilmen için bütün sınırları kaldırdık. Artık yalnızca bulunduğun bölgeden değil dünyanın her yerinden global yeteneklerle, özgürce çalışabilmen için...

          </p>
        </div>
        <div class="illustration-inner">
          <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/contact-order_bi.png"/>
          <p class="illustration-main-paragraph">Bakış Açını Genişlet
          </p>
          <p class="illustration-paragraph">
            El elden, göz gözden üstündür. Hedef kitleni ne kadar genişletmek istersen, o kadar farklı gözle bakmayı öğrenirsin. Biz sizin için her pencereyi açtık, bakmak size kaldı.

          </p>
        </div>
        <div class="illustration-inner" style="margin-left: 41px;">
          <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/done_bi.png"/>
          <p class="illustration-main-paragraph">Aklın Yolu Free</p>
          <p class="illustration-paragraph">
            Becerikli bir işveren, kaynaklarını en verimli nasıl kullanacağını bilen iş verendir. Kaynaklarını olabildiğince verimli kullanmak adına freelancer’lara şans verin; göreceksin ki her akıl, her işe değer katar.
          </p>
        </div>
      </div>

      <div class="mainpage-learnmore-div">
        <router-link style="text-decoration: none" to="/register">Hemen Kaydol</router-link>
      </div>

      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
    </div>
  </div>
</template>

<script>

import singleGig from "@/pages/components/singleGig";
  export default {
    name: "src-pages-body-marketplace-goLanding-v1",
    components:{singleGig},
    data() {
      return {
        gigLists: null,
        header:"freelancerlar'la",
        impressionUUID:'',
      }
    },

    methods: {

      openOnboardingModal(){
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ONBOARDING,info:{url:null}});
      },

      getGoPage() {
        let slug = this.$store.state.routerParams[0];
        this.api.content.getGoPage(slug)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              let trackGigObj = [];
              result.data.gig_lists.forEach((gigl) => {
                gigl.gigs.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                })
              });
              let trackingProps = {
                page:'landingpagego',
                placement:result.data.slug,
                info:trackGigObj,
                title: this.impressionUUID
              };
             this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);


              this.gigLists = result.data.gig_lists;
              this.title = result.data.title;
              this.header = result.data.header;
              this.Helper.trackEvents.pageView(this.Helper.goLandingPageProps(result.data.id, result.data.slug, result.data.title));
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      updateLikeStatusForGig(receivedGig) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, receivedGig.likeStatus.action, receivedGig.uuid,null,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;

            if (result.success) {
            } else {
              this.$router.push(result.data.redirect_url);
              this.revertLikeStatus(receivedGig);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus(receivedGig);
          });
        this.reverseLikeStatus(receivedGig);
      },

      revertLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        }
      },

      reverseLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        }
      },

    },

    watch: {
    },

    created() {
      this.impressionUUID = this.Helper.generateUUID();
      this.getGoPage();
    }

  }
</script>

<style scoped lang="scss">

  .get-started-button {
    border-radius: 2px;
    background-image: linear-gradient(45deg, rgb(230, 50, 98), rgb(253, 64, 86));

    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
  }
  .get-started-button:hover {


    background-image: linear-gradient(45deg, #ee2344, #ee2344);

  }

  .explore-category-div {

    height: 70px;
    background-color: #f4f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .explore-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .explore-category-name {
    font-size: 15px;
    color: #8b95a1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .explore-category-name:hover {
    color: #2d3640;
  }

  .explore-category-separator {
    width: 2px;
    height: 69.5px;
    border-right: solid 1px #d9dfe5;
    margin-left: 19px;
    margin-right: 21px;
  }

  /*giglist*/



  .explore-marketplace-gig-container {
    padding: 0 60px 30px;
    min-width: 1170px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .explore-marketplace-gig-container h1 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 53px;
    margin-bottom: 42px;
  }
  .swiper-button-prev {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_left.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    left: 0;
    opacity: 1;
    border-left: none;
  }

  .swiper-button-next {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_right.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    right: 0;
    opacity: 1;
    border-right: none;
  }

  .swiper-button-disabled {
    opacity: 0.7;
    pointer-events: all !important;
  }

  .marketplace-gigs-body {
    display: inline-block;
    margin-right: 29px;
    margin-bottom: 20px;
    width: 263px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
    text-align: left;

  }

  .marketplace-gigs-userinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }

  /*illustration*/
  .mainpage-illustration {
    padding-bottom: 50px;
    padding-top: 61px;
  }

  .mainpage-illustration h1 {
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    color: #000;
    margin: 0;

  }

  .illustration-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 43px;
  }

  .illustration-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .illustration-main-paragraph {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 22px;
  }

  .illustration-paragraph {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.44;
    text-align: center;
    color: #8b95a1;
    word-break: break-word;
    width: 350px;
    height: 152px;
    margin-top: 21px;
  }

  .mainpage-learnmore-div {
    width: 220px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px rgba(75,79,82, 0.2);
    margin: auto;
  }

  .mainpage-learnmore-div a {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-decoration: none;
  }


  /*bottom*/

  .mainpage-bottom {
    background-image: linear-gradient(79deg, rgba(230,50,98, 0.9), rgba(253,64,86, 0.9));
    overflow: hidden;
    position: relative;
    height: 158px;
    padding-top: 119px;
    padding-bottom: 119px;
  }

  .mainpage-bottom h1 {
    font-size: 42px;
    font-weight: 300;
    color: #ffffff;
    padding-bottom: 30px;
    margin: auto;
    text-align: center;
  }

  .mainpage-bottom-button-div {
    margin: auto;
    position: relative;
    width: 270px;
    height: 70px;

  }

  .mainpage-bottom-button {
    width: 270px;
    height: 70px;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    color: #2d3640;
    border: none;
  }

  .mainpage-bottom-button:hover {
    background-color: #f6f6f6 !important;
  }

  .bottom-right {
    position: absolute;
    display: flex;
    align-items: center;
    top: 30px;
    right: -215px;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }



  .homeH1 {

    font-size: 52px;
    font-weight: 300;
    text-align: center;
    color: #2d3640;
  }

  .homeH1 span {
    font-weight: 500;
  }

  .header-info {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.33;
    text-align: center;
    color: #8b95a1;
    margin-top: 30px;
    margin-bottom: 30px;

  }





  .top-buy-button {
    width: 211px;
    height: 50px;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    padding-right: 16px;
    padding-left: 20px;
    padding-bottom: 3px;
    background-color: #e63262!important;

  }

  .top-buy-button:hover {
    background-color: #de1e51!important;

  }

  .bottom-buy-button {
    width: 211px;
    height: 50px;
    border-radius: 2px;
    background-color: #26ca9a;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    padding-right: 16px;
    padding-left: 20px;
    padding-bottom: 3px;

  }

  .bottom-buy-button:hover {
    background-color: #00a575 !important;
  }


</style>
